import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import $ from "jquery"

export default class extends Controller {
  static targets = ["backgroundImage"]
  static values = {
    images: Array,
    currentId: Number,
    updateUrl: String,
    layoutType: String,
  }

  declare readonly backgroundImageTarget: HTMLElement
  declare readonly hasBackgroundImageTarget: boolean

  declare imagesValue: { id: number; image: string }[]
  declare currentIdValue: number
  declare updateUrlValue: string
  declare layoutTypeValue: string

  getBackgroundTarget() {
    return this.hasBackgroundImageTarget ? $(this.backgroundImageTarget) : $("#cover-img")
  }

  updateImageOnServer(layoutId) {
    const fieldName = this.layoutTypeValue === "last_page" ? "last_page_id" : "cover_page_id"
    return patch(this.updateUrlValue, { body: { template: { [fieldName]: layoutId } } })
  }

  updateBackgroundImage = (index) => {
    this.getBackgroundTarget().css("background-image", `url(${this.imagesValue[index].image})`)
    this.currentIdValue = this.imagesValue[index].id
  }

  next() {
    const currentIndex = this.imagesValue.findIndex(({ id }) => id === this.currentIdValue)
    const nextIndex = (currentIndex + 1) % this.imagesValue.length

    this.updateImageOnServer(this.imagesValue[nextIndex].id).then(() =>
      this.updateBackgroundImage(nextIndex),
    )
  }

  previous() {
    const currentIndex = this.imagesValue.findIndex(({ id }) => id === this.currentIdValue)
    const previousIndex = (currentIndex - 1 + this.imagesValue.length) % this.imagesValue.length

    this.updateImageOnServer(this.imagesValue[previousIndex].id).then(() =>
      this.updateBackgroundImage(previousIndex),
    )
  }
}
