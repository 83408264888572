import {Controller} from "@hotwired/stimulus"
import {patch} from "@rails/request.js"
import {DirectUpload} from "@rails/activestorage"
import {blobToFile, loadingOverlay} from "../lib/utils";

type RecorderState = "empty" | "ready" | "recording" | "playing"

export default class extends Controller<HTMLElement> {
  static targets = ["audioPlayer"]
  static values = { inviteUuid: String, directUploadUrl: String, state: String }

  declare inviteUuidValue: string
  declare directUploadUrlValue: string
  declare stateValue: RecorderState
  declare readonly audioPlayerTarget: HTMLAudioElement

  private audioChunks: Blob[]
  private stream: MediaStream
  private mediaRecorder: MediaRecorder

  async startRecording() {
    this.audioChunks = []
    this.stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    this.mediaRecorder = new MediaRecorder(this.stream)

    this.mediaRecorder.ondataavailable = event => {
      this.audioChunks.push(event.data)
    }

    this.mediaRecorder.onstart = () => {
      this.stateValue = "recording"
    }

    this.mediaRecorder.onstop = () => {
      const blob = new Blob(this.audioChunks, { type: "audio/ogg" })
      this.stream.getTracks().forEach(track => track.stop())
      this.audioChunks = []
      this.audioPlayerTarget.src = window.URL.createObjectURL(blob)
      this.stateValue = "ready"
      this.saveUpload(blob)
    }

    this.mediaRecorder.start()
  }

  stopRecording() {
    this.mediaRecorder.stop()
  }

  playAudio() {
    this.stateValue = "playing"
    void this.audioPlayerTarget.play()
  }

  stopPlaying() {
    this.stateValue = "ready"
    this.audioPlayerTarget.pause()
    this.audioPlayerTarget.currentTime = 0
  }

  stop() {
    if (this.stateValue === "recording") {
      this.stopRecording()
    } else if (this.stateValue === "playing") {
      this.stopPlaying()
    }
  }

  async deleteAudio() {
    this.audioPlayerTarget.src = ""
    this.stateValue = "empty"

    patch(`/invites/${this.inviteUuidValue}`, {
      body: { invite: { voice_message: null } }
    })

    const form = document.getElementById("quiz-form") as HTMLFormElement
    const audioField = form.elements["invite[voice_message]"]
    if (audioField) {
      audioField.remove()
    }
  }

  saveUpload(blob) {
    const audioFile = blobToFile(blob)

    loadingOverlay.show()
    new DirectUpload(audioFile, this.directUploadUrlValue).create((error, blob) => {
      loadingOverlay.hide()
      if (error) {
        alert(error)
      } else {
        patch(`/invites/${this.inviteUuidValue}`, {
          body: { invite: { voice_message: blob.signed_id } }
        })
      }
    })
  }
}
