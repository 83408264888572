import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller<HTMLElement> {
  static targets = ["arrow"];

  static values = {
    rowCount: Number
  };

  declare readonly arrowTarget: HTMLImageElement

  shuffle(direction) {
    const currentRow = this.element; // Target the parent row
    const parent = currentRow.parentNode;
    const siblingRow = (direction > 0 ? currentRow.nextElementSibling : currentRow.previousElementSibling) as HTMLElement;
    const childrenArray = Array.from(parent.children);
    const currentIndex = childrenArray.indexOf(currentRow);
    if (siblingRow) {
      const arrowTargetSpan = this.arrowTarget;
      const siblingSpan = siblingRow.querySelector("span");
      if (direction > 0) {
        parent.insertBefore(siblingRow, currentRow);
      } else {
        parent.insertBefore(currentRow, siblingRow);
      }
      if (siblingSpan && arrowTargetSpan) {
        const tempContent = arrowTargetSpan.innerHTML;
        arrowTargetSpan.innerHTML = siblingSpan.innerHTML;
        siblingSpan.innerHTML = tempContent;
      }
      this.updateOrder(currentRow.dataset.inviteUuid, siblingRow.dataset.inviteUuid);
    }
  }

  async updateOrder(current_invite_uuid: string, sibling_invite_uuid: string): Promise<void> {
    post("/book_creation/swap_invites_order", {
      body: { current_invite_uuid:  current_invite_uuid, sibling_invite_uuid: sibling_invite_uuid }
    })
  }

  shuffleUp() {
    this.shuffle(-1);
  }

  shuffleDown() {
    this.shuffle(1);
  }
}
