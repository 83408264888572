import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    inviteUuid: String
  }

  declare inviteUuidValue: string

  async toggle(event) {
    const csrfToken = (document.getElementsByName("csrf-token")[0] as HTMLMetaElement).content
    const participantElements = document.querySelectorAll<HTMLElement>(".participant-avatar");
    const downloadAvtarBtn = document.querySelector("#download-avatar")
    const avatar_hidden_val = event.currentTarget.checked
    let response
    if (this.inviteUuidValue === '') {
      const hiddenField = document.getElementById("avatar_hidden") as HTMLInputElement;
      if (hiddenField) {
        hiddenField.value = avatar_hidden_val;
      }
    }
    if(this.inviteUuidValue !== ''){
      response = await fetch(`/invites/${this.inviteUuidValue}`, {
        method: "PATCH",
        body: JSON.stringify({ invite: { avatar_hidden: avatar_hidden_val } }),
        headers: { "X-CSRF-Token": csrfToken, "Content-Type": "application/json" }
      })
    }
    if ((this.inviteUuidValue === '') || response.ok ) {
      participantElements.forEach(column => {
        const avatarElement = column.querySelector(".photo-slider");
        if (avatar_hidden_val) {
          const nonprofileElements = column.querySelectorAll(".photo-position");
          const profileElement = column.querySelector(".profile-img-container");
          profileElement.classList.remove("d-none")
          column.dataset.personalPhotoModalIdValue = "photo-upload-modal";
          avatarElement.classList.add("d-none");
          nonprofileElements.forEach(element => {
            if (element.tagName === "BUTTON"){
              element.classList.replace("photo-position", "enlarged-photo");
              element.classList.replace("transparent-button", "adjusted-button");
            }else{
              element.classList.add("d-none")
            }
          });
          downloadAvtarBtn.classList.add("d-none")
        } else {
          const buttonElement = column.querySelector(".enlarged-photo");
          buttonElement.classList.replace("enlarged-photo", "photo-position");
          buttonElement.classList.replace("adjusted-button", "transparent-button");
          const profileElement = column.querySelector(".profile-img-container");
          profileElement.classList.add("d-none")
          const nonprofileElements = column.querySelectorAll(".photo-position");
          column.dataset.personalPhotoModalIdValue = "personal-upload-modal";
          avatarElement.classList.remove("d-none");
          nonprofileElements.forEach(element => {
            element.classList.remove("d-none");
          });
          downloadAvtarBtn.classList.remove("d-none")
        }
      });
    } else {
      console.error("Request failed with status:", response.status);
    }
  }
}
