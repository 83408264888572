import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

const ROTATION_STEP = -90

export default class extends Controller {
  static values = {
    id: String,
    rotation: { type: Number, default: 0 }
  }
  static targets = ["image"]
  declare idValue: string
  declare rotationValue: number
  declare readonly imageTarget: HTMLImageElement

  async rotate(event: Event) {
    this.rotationValue += ROTATION_STEP
    this.imageTarget.style.transform = `rotate(${this.rotationValue}deg)`

    const res = await post("/book_creation/rotate_picture", {
      body: { rotation: ROTATION_STEP, signed_id: this.idValue },
    })

    if (!res.ok) {
      return
    }

    this.element.dispatchEvent(
      new CustomEvent("rotatePicture", {
        bubbles: true,
        detail: {
          rotation: this.rotationValue,
        },
      }),
    )
  }
}
