import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.css"

const flatpickrOptions = {
  minDate: "today",
  weekNumbers: true,
  dateFormat: "Y-m-d",
  time_24hr: true,
  enableTime: false,
  allowInput: true
}

export default class extends Controller {
  connect() {
    flatpickr(this.element, flatpickrOptions)
  }
}
