import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    userEmail: String
  }

  declare userEmailValue: string

  connect() {
    const transferBooksToggle = document.getElementById("transfer-books-toggle") as HTMLInputElement;

    if (transferBooksToggle) {
      transferBooksToggle.addEventListener("change", () => {
        const userDropdown = document.getElementById("userDropdown") as HTMLSelectElement;
        if (userDropdown) {
          userDropdown.style.display = transferBooksToggle.checked ? "block" : "none";
        }
      });
    }

    const transferForm = document.getElementById("transferForm") as HTMLFormElement;

    if (transferForm && this.userEmailValue) {
      let hiddenField = transferForm.querySelector('input[name="user_email"]') as HTMLInputElement;
      if (!hiddenField) {
        hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = "user_email";
        transferForm.appendChild(hiddenField);
      }
      
      hiddenField.value = this.userEmailValue;
    }
    this.removeUserFromDropdown();
  }

  removeUserFromDropdown() {
    const userDropdown = document.querySelector("#newOwner") as HTMLSelectElement;
    if (userDropdown) {
      for (let i = 0; i < userDropdown.options.length; i++) {
        if (userDropdown.options[i].value === this.userEmailValue) {
          userDropdown.remove(i);
          break;
        }
      }
    }
  }
}
