import { Controller } from "@hotwired/stimulus"
import { FetchResponse, get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["contentList"]

  declare readonly contentListTarget: HTMLElement

  async onAddAsset(event: CustomEvent<{ result: FetchResponse }>) {
    const signedAssetId = (await event.detail.result.json).id

    get("/participant/append_full_size_contents", {
      query: { content_id: signedAssetId },
    })
      .then((response) => {
        if (response.ok) {
          return response.html
        } else {
          alert("Error uploading file")
        }
      })
      .then((html) => {
        this.contentListTarget.insertAdjacentHTML("beforeend", html)
      })
  }

  onDeleteAsset(event) {
    event.currentTarget.remove()
  }
}
