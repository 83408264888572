import { Controller } from "@hotwired/stimulus"

const emailRegexp = new RegExp(
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
)

export default class extends Controller {
  static targets = ["list", "input", "warning"]
  static values = { emails: Array }

  declare readonly listTarget: HTMLElement
  declare readonly inputTarget: HTMLInputElement
  declare readonly warningTarget: HTMLElement

  declare emailsValue: string[]

  connect() {
    if (this.emailsValue) {
      this.emailsValue.forEach((email) => {
        this.addEmailBadge(email)
      })
    }
  }

  focusInput() {
    this.inputTarget.focus()
  }

  removeEmail(event) {
    event.preventDefault()
    event.currentTarget.parentNode.remove()
  }

  addEmailBadge(email) {
    if (email.endsWith("t-online.de") || email.endsWith("hotmail.com")) {
      this.warningTarget.classList.remove("d-none")
    }

    this.listTarget.querySelector("li:last-of-type").insertAdjacentHTML(
      "beforebegin",
      `
      <li class="multiple_emails-email">
        <span>${email}</span>
        <button type="button" class="plain-button text-dark" title="Remove" data-action="email-input#removeEmail"><i class="bi bi-x-lg"></i></button>
        <input type="hidden" name="invite_emails[]" value="${email}">
      </li>
    `,
    )
  }

  handleInput(event) {
    if (!event.inputType.startsWith("insertFrom")) {
      return
    }

    const parts = this.inputTarget.value.split(/[\s;,]+/)

    for (const part of parts) {
      if (emailRegexp.test(part)) {
        this.addEmailBadge(part)
      }
    }

    this.inputTarget.value = ""
  }

  handleKey(event) {
    this.inputTarget.classList.remove("multiple_emails-error")
    let value = this.inputTarget.value.trim()

    if (!value.length && event.key == "Enter") {
      // Allow form submit when input field is empty
      return
    }

    if (event.key == "Enter" || event.key == " " || event.key == ",") {
      event.preventDefault()

      if (emailRegexp.test(value)) {
        this.addEmailBadge(value)
        this.inputTarget.value = ""
      } else {
        this.inputTarget.value = value
        this.inputTarget.classList.add("multiple_emails-error")
      }
    }
  }
}
