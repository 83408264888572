import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import $ from "jquery"

export default class extends Controller {
  static values = {
    bookUuid: String,
  }

  declare bookUuidValue: string

  async toggleAllowImages(event) {
    await patch(`/books/${this.bookUuidValue}`, {
      body: { book: { allow_to_other_participants: event.currentTarget.checked } },
    })
  }

  async toggleAllowSinglePage(event) {
    await patch(`/books/${this.bookUuidValue}`, {
      body: { book: { allow_single_page: event.currentTarget.checked } },
    })
  }

  async toggleShowNames(event) {
    if (event.currentTarget.checked) {
      $(".show-name").removeAttr("style")
    } else {
      $(".show-name").css({ display: "none" })
    }
    await patch(`/books/${this.bookUuidValue}`, {
      body: { book: { show_name: event.currentTarget.checked } },
    })
  }

  async voiceToggle(event) {
    await patch(`/books/${this.bookUuidValue}`, {
      body: { book: { allow_voice_message: event.currentTarget.checked } },
    })
  }

  async toggleAllowVideos(event: InputEvent) {
    await patch(`/books/${this.bookUuidValue}`, {
      body: { book: { allow_videos: (event.currentTarget as HTMLInputElement).checked } },
    })
  }
}
