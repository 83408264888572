import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import { patch } from "@rails/request.js"

export default class extends Controller {
  [x: string]: any
  static targets = ["individualInput", "individualBtn"]

  static values = {
    hexcolor: String,
    inviteUuid: String,
  }

  declare hexcolorValue: string
  declare inviteUuidValue: string

  connect() {
    const inputElement = this.element.querySelector(
      `input[value="${this.hexcolorValue}"]`
    ) as HTMLInputElement
    if (inputElement !== null) {
      inputElement.checked = true
    } else {
      console.error("Input element not found")
    }
  }

  async updateColor(event) {
    const newColor = event.target.value
    const isDark = this.isDarkColor(newColor)

    await patch(`/invites/${this.inviteUuidValue}`, { body: { invite: { hex_color: newColor } } })

    Array.from(document.getElementsByClassName("participant-color")).forEach((e) => {
      const element = e as HTMLElement
      element.style.setProperty("--accent-color", newColor)

      const titleElements = element.getElementsByClassName("title")
      Array.from(titleElements).forEach((titleElement) => {
        if (isDark) {
          titleElement.classList.add("white-text")
        } else {
          titleElement.classList.remove("white-text")
        }
      })
      const bookTitleElements = element.getElementsByClassName("book-title")
      Array.from(bookTitleElements).forEach((bookTitleElement) => {
        const h6Elements = bookTitleElement.getElementsByTagName("h6")
        Array.from(h6Elements).forEach((h6Element) => {
          if (isDark) {
            h6Element.classList.add("white-text")
          } else {
            h6Element.classList.remove("white-text")
          }
        })
      })
    })

    this.individualBtnTarget.style.setProperty("background", newColor)
    this.hexcolorValue = newColor
  }

  individual_color_trigger() {
    this.individualInputTarget.click()
  }

  isDarkColor(color) {
    const hexToRgb = (hex) => {
      const bigint = parseInt(hex.slice(1), 16)
      const r = (bigint >> 16) & 255
      const g = (bigint >> 8) & 255
      const b = bigint & 255
      return [r, g, b]
    }

    const rgb = hexToRgb(color)
    const luminance = 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]
    const threshold = 128

    return luminance < threshold
  }
}
