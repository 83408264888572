import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {

  static values = {
    singlePage: Boolean
  }

  $unattachedNodes = $(document.createElement("div"))

  connect() {
    window.setTimeout(() => {
      if (this.singlePageValue) {
        this.setActiveSlideSet("single")
      } else {
        this.setActiveSlideSet("double")
      }
      $("#book-slider-invite").slick("refresh")
      $("#book-slider-cover").slick("refresh")
    }, 300)
  }

  toggle() {
    this.singlePageValue = !this.singlePageValue
    $("#quiz-form").find(`input[name="invite[single_page]"]`).remove()
    $("#quiz-form").append(`<input type="hidden" name="invite[single_page]" value="${this.singlePageValue}">`)

    $("#own-content-form").find(`input[name="invite[single_page]"]`).remove()
    $("#own-content-form").append(`<input type="hidden" name="invite[single_page]" value="${this.singlePageValue}">`)

    if (this.singlePageValue) {
      this.setActiveSlideSet("single")
    } else {
      this.setActiveSlideSet("double")
    }

    window.setTimeout(() => {
      const selectedAvatarId = this.element.dataset.selectedAvatarId
      if (selectedAvatarId) {
        const photoSlider = this.singlePageValue ? $("#photo-slider-single") : $("#photo-slider-double")
        let targetIndex = null

        $(photoSlider).find(".slick-slide").each((index, slide) => {
          const avatarImg = slide.querySelector(".slide .avatar")
          if (avatarImg && avatarImg.dataset.avatarId === selectedAvatarId) {
            targetIndex = index
            return false
          }
        });

        if (targetIndex !== null) {
          $(photoSlider).slick("slickGoTo", targetIndex)
        }
      }
    }, 300)

    $("#book-slider-invite").slick("refresh")
    $("#book-slider-cover").slick("refresh")
  }

  setActiveSlideSet(responseType) {
    const slidesToBeRemoved = $(`div.slick-slide div.slide[data-response-type]`)
    const removeStartIndex = Number($(`div.slick-slide:has([data-response-type])`).first().attr("data-slick-index"))

    for (let i = 0; i < slidesToBeRemoved.length; i++) {
      $("#book-slider-invite").slick("slickRemove", removeStartIndex)
      $("#book-slider-cover").slick("slickRemove", removeStartIndex)
    }

    this.$unattachedNodes.append(slidesToBeRemoved)
    this.$unattachedNodes.children(`[data-response-type=${responseType}]`).each((index, child) => {
      // Need to add two extra divs, due to a bug in SlickSlider
      // See https://github.com/kenwheeler/slick/issues/3324
      const slideDiv = document.createElement("div")
      slideDiv.appendChild(document.createElement("div"))
      slideDiv.firstElementChild.appendChild(child)

      $("#book-slider-invite").slick("slickAdd", slideDiv, removeStartIndex + index, true)
      $("#book-slider-cover").slick("slickAdd", slideDiv, removeStartIndex + index, true)
    })
  }
}
