import { Controller } from "@hotwired/stimulus"
import { loadingOverlay, openModalFromTemplate } from "../lib/utils"
import { get } from "@rails/request.js"
import { Modal } from "bootstrap"

export default class extends Controller {
  static values = {
    url: String,
    redirectEvents: String,
    closeEvents: String,
  }

  declare urlValue: string
  declare redirectEventsValue: string
  declare closeEventsValue: string

  abortController: AbortController | null = null
  modalEl: Element | null = null

  connect() {
    this.abortController = new AbortController()
  }

  disconnect() {
    this.abortController.abort()
  }

  async open(event: Event) {
    event.preventDefault()

    if (!this.urlValue) {
      throw new Error("remoteModal urlValue is missing")
    }

    if (this.urlValue.startsWith("#")) {
      this.modalEl = await this.openLocalModal()
    } else {
      this.modalEl = await this.openRemoteModal()
    }

    if (this.redirectEventsValue) {
      this.redirectEventsValue.split(/ +/).forEach((eventName) => {
        this.modalEl.addEventListener(
          eventName,
          (event: CustomEvent) => {
            event.stopPropagation()
            this.element.dispatchEvent(
              new CustomEvent(eventName, {
                bubbles: event.bubbles,
                cancelable: event.cancelable,
                detail: event.detail,
              }),
            )
          },
          { signal: this.abortController.signal },
        )
      })
    }

    if (this.closeEventsValue) {
      this.closeEventsValue.split(/ +/).forEach((eventName) => {
        this.modalEl.addEventListener(
          eventName,
          (event: CustomEvent) => {
            this.close()
          },
          { signal: this.abortController.signal },
        )
      })
    }
  }

  async openRemoteModal() {
    loadingOverlay.show(100)
    const response = await get(this.urlValue)
    loadingOverlay.hide()

    if (!response.ok) {
      throw new Error(`remoteModal failed to load ${this.urlValue}`)
    }

    const modalHtml = await response.text
    return openModalFromTemplate(modalHtml)
  }

  async openLocalModal() {
    const modalEl = document.querySelector(this.urlValue)

    if (!modalEl) {
      throw new Error(`Unable to find modal element ${this.urlValue}`)
    }

    Modal.getOrCreateInstance(modalEl).show()
    return modalEl
  }

  close() {
    if (!this.modalEl) {
      return
    }

    Modal.getOrCreateInstance(this.modalEl).hide()
  }
}
