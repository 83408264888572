import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["book-slider"]

  declare readonly hasBookSliderOutlet: boolean
  declare readonly bookSliderOutlet: any

  reloadBookPictures(event) {
    if (!this.hasBookSliderOutlet) {
      throw new Error("No book-slider outlet found")
    }

    this.bookSliderOutlet.reloadPhotoUploadPages(event)
  }
}
