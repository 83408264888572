import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap"

export default class extends Controller {
  static values = { contents: String }

  declare contentsValue: string

  copyToClipboard() {
    const temp = document.createElement("input")
    temp.value = this.contentsValue
    document.body.append(temp)
    temp.select()
    document.execCommand("copy")
    temp.blur()
    temp.remove()
  }

  copyWithTooltip(event) {
    event.preventDefault()
    this.copyToClipboard()
    const tt = Tooltip.getOrCreateInstance(this.element, { title: "Copied", trigger: "manual" })
    tt.show()
    setTimeout(() => tt.hide(), 1000)
  }

  copyWithClass(event) {
    event.preventDefault()
    this.copyToClipboard()
    this.element.classList.add("copied")
    setTimeout(() => this.element.classList.remove("copied"), 1000)
  }
}
