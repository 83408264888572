import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["themeRadio", "themeSelect"]
  static values = {
    themeId: String
  }

  declare readonly themeRadioTargets: Array<HTMLInputElement>
  declare readonly themeSelectTarget: HTMLSelectElement
  declare themeIdValue: string

  themeIdValueChanged() {
    for (const target of this.themeRadioTargets) {
      target.checked = (target.value === this.themeIdValue)
    }

    this.themeSelectTarget.value = this.themeIdValue
  }

  setThemeId(event: Event) {
    const target = event.currentTarget as HTMLInputElement
    this.themeIdValue = target.value
  }
}
