import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["profileImage", "commonImage", "avatarImage"]

  declare readonly commonImageTarget: HTMLImageElement
  declare readonly profileImageTarget: HTMLImageElement
  declare readonly avatarImageTarget: HTMLImageElement

  loadImages(sources: string[], onAllLoaded: (images: HTMLImageElement[]) => void) {
    let numLoading = sources.length;
    const images: HTMLImageElement[] = [];
    const onload = () => --numLoading === 0 && onAllLoaded(images);
  
    for (const source of sources) {
      const img = new Image();
      img.onload = onload;
      img.crossOrigin = "anonymous";
      img.src = source;
      images.push(img);
    }
  }
  


  combineAndDownload() {
    if (this.profileImageTarget.src === "") {
      const errorElement = document.querySelector(".no-profile-error")
      const errorText = errorElement.textContent
      alert(errorText)
      return
    }

    const slider = document.querySelector(".photo-slider")
    let avatarImage: HTMLImageElement

    if (slider) {
      const slickActive = slider.querySelector(".slick-active")
      avatarImage = slickActive ? slickActive.querySelector(".avatar") : this.avatarImageTarget
    }

    let avatarSrc = avatarImage ? avatarImage.src : '';
    let profileSrc = this.profileImageTarget.src;

    let avatarUrl = new URL(avatarSrc.toString());
    avatarUrl.searchParams.set("v", "myavatar");

    let profileUrl;
    if (profileSrc.startsWith('blob:')) {
      profileUrl = profileSrc;
    } else {
      profileUrl = new URL(profileSrc);
      profileUrl.searchParams.set("v", "myavatar");
    }

    this.loadImages([avatarUrl.toString(), profileUrl.toString()], ([avatarImage, profileImage]) => {
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")

      canvas.width = this.commonImageTarget.width
      canvas.height = this.commonImageTarget.height

      const avatarWidth = 864
      const avatarHeight = 894

      const profileWidth = 202
      const profileHeight = 283

      const avatarX = 108
      const avatarY = 108

      const profileX = 435
      const profileY = 152

      ctx.drawImage(profileImage, profileX, profileY, profileWidth, profileHeight)
      ctx.drawImage(avatarImage, avatarX, avatarY, avatarWidth, avatarHeight)
      ctx.drawImage(this.commonImageTarget, 0, 0)

      const combinedImageDataURL = canvas.toDataURL("image/png")

      const downloadLink = document.createElement("a")
      downloadLink.href = combinedImageDataURL
      downloadLink.download = "MyBooks4MemoriesAvatar.png"
      downloadLink.click()
    })
  }
}
