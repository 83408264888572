import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import {onVisible} from "../lib/utils";

export default class extends Controller {
  connect() {
    onVisible(this.element, () => {
      $(this.element).not(".slick-initialized").slick({
        slidesToScroll: 1,
        slidesToShow: 1,
        dots: false,
        arrows: true,
        fade: true,
        nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="bi bi-chevron-right"></i></button>',
        prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="bi bi-chevron-left"></i></button>',
        speed: 1000,
        adaptiveHeight: true,
      }).on("beforeChange", function(event, slick, currentSlide, nextSlide) {
        window.setTimeout(() => {
          const nextSlideElement = slick.$slides.get(nextSlide)
          if (nextSlideElement) {
            const profileToggle = document.querySelector('[data-controller="toggle-single-page-invite"]') as HTMLElement
            profileToggle.dataset.selectedAvatarId = nextSlideElement.querySelector(".slide .avatar").dataset.avatarId
          }
          const avatarImg = document.querySelector(".photo-slider .slick-current .slide .avatar") as HTMLElement
          (document.getElementById("invite_avatar_id") as HTMLInputElement).value = avatarImg.dataset.avatarId
        })
      })

      const avatarImg = document.querySelector(".photo-slider .slick-current .slide .avatar") as HTMLElement
      if (avatarImg) {
        (document.getElementById("invite_avatar_id") as HTMLInputElement).value = avatarImg.dataset.avatarId
      }
    })
  }

  disconnect() {
    $(this.element).filter(".slick-initialized").slick("unslick")
  }
}
