import { Controller } from "@hotwired/stimulus"
import { FetchResponse } from "@rails/request.js"

export default class extends Controller {
  static targets = ["photoList"]
  static values = { bookId: String, photoId: String, inviteUuid: String }

  declare bookIdValue: string
  declare photoIdValue: string
  declare inviteUuidValue: string

  declare readonly photoListTarget: HTMLElement

  async onAddAsset(event: CustomEvent<{ result: FetchResponse }>) {
    const addedElement = await event.detail.result.html
    this.photoListTarget.insertAdjacentHTML("beforeend", addedElement)
  }

  onDeletePicture(event) {
    event.currentTarget.remove()
  }

  onMovePicture(event) {
    if (event.detail.direction === "up") {
      const previousElement = event.currentTarget.previousElementSibling
      if (previousElement) {
        event.currentTarget.after(previousElement)
      }
    } else if (event.detail.direction === "down") {
      const nextElement = event.currentTarget.nextElementSibling
      if (nextElement) {
        event.currentTarget.before(nextElement)
      }
    }
  }

  onRotatePicture(event) {
    const image = event.currentTarget.querySelector("img")
    image.style.transform = `rotate(${event.detail.rotation}deg)`
  }
}
