import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLTextAreaElement> {
  connect() {
    // this.adjustFontSize()
    // this.element.addEventListener("input", () => this.adjustFontSize())
  }

  adjustFontSize() {
    const textarea = this.element
    const MIN_FONT_SIZE = 0.375
    const SIZE_DELTA = 0.0625
    textarea.style.fontSize = "1.6em"
    this.updateFontSizeAttribute()

    const adjust = () => {
      const currentFontSize = parseFloat(window.getComputedStyle(textarea).fontSize) / 10
      console.log(currentFontSize)

      if (textarea.scrollHeight > textarea.clientHeight && currentFontSize > MIN_FONT_SIZE) {
        const newFontSize = currentFontSize - SIZE_DELTA
        textarea.style.fontSize = `${newFontSize}em`
        this.updateFontSizeAttribute()
        requestAnimationFrame(adjust)
      } else {
        // this.updateFontSizeAttribute()
      }
    }

    adjust()
  }

  updateFontSizeAttribute() {
    const textarea = this.element
    const quizId = textarea.id.match(/quizzes_(\d+)_answer/)[1]
    const hiddenFontSizeField = document.querySelector(`#font-size-${quizId}`)
    if (hiddenFontSizeField) {
      ;(hiddenFontSizeField as HTMLInputElement).value = textarea.style.fontSize
    }
  }
}
