import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["individualInput", "individualBtn"]
  declare readonly individualInputTarget: HTMLInputElement;
  declare readonly individualBtnTarget: HTMLElement;

  individual_color_trigger(event) {
    event.preventDefault();
    this.individualInputTarget.click();
  }

  updateColor(event) {
    const newColor = event.target.value;
    this.individualBtnTarget.style.backgroundColor = newColor;
    this.individualInputTarget.value = newColor;
  }
}
