import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ["logoImage", "logoField"]
  declare readonly logoImageTarget: HTMLImageElement;
  declare readonly logoFieldTarget: HTMLInputElement;

  openModal() {
    const modalEl = document.getElementById("logo-upload-modal");
    const modal = Modal.getOrCreateInstance(modalEl);

    const onSave = (event) => {
      const { imageUrl, imageId, orientation } = event.detail;
      
      this.logoImageTarget.src = imageUrl;
      this.logoFieldTarget.value = imageId;

      modal.hide();
    }

    function onModalHidden() {
      modalEl.removeEventListener("hidden.bs.modal", onModalHidden);
      modalEl.removeEventListener("saveUpload", onSave);
    }

    modalEl.addEventListener("hidden.bs.modal", onModalHidden);
    modalEl.addEventListener("saveUpload", onSave);

    modal.show();
  }
}
