import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["password", "newPassword", "confirmPassword"]

  declare readonly passwordTarget: HTMLInputElement
  declare readonly newPasswordTarget: HTMLInputElement
  declare readonly confirmPasswordTarget: HTMLInputElement

  toggleVisibility() {
    const passwordInput = this.passwordTarget
    const passwordShown = passwordInput.type === "text"
    passwordInput.type = passwordShown ? "password" : "text"
  }

  enforceMinLength() {
    const minLength = 8
    const passwordInput = this.passwordTarget.value
    const errorMessageElement = document.getElementById("password-error-message")
    if (passwordInput.length > 0 && passwordInput.length < minLength) {
      errorMessageElement.classList.remove("d-none")
    } else {
      errorMessageElement.classList.add("d-none")
    }
  }
}
