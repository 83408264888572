import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static values = {
    bookUuid: String
  }

  declare bookUuidValue: string

  async toggle(event) {
    const allow_others_purchase_val = event.currentTarget.checked

    const response = await patch(`/books/${this.bookUuidValue}`, {
      body: { book: { allow_others_purchase: allow_others_purchase_val } }
    })
  }
}
