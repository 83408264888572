import { Controller } from "@hotwired/stimulus"

function preventEmojiInput(event: InputEvent) {
  const emojiRe = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
  const input = event.target as HTMLInputElement

  if (event.data && event.data.match(emojiRe)) {
    if (event.inputType.match(/^insert.*Text$/)) {
      event.preventDefault()
    }

    window.setTimeout(() => {
      if (input.value.match(emojiRe)) {
        input.value = input.value.replaceAll(emojiRe, '')
      }
    })
  }
}

export default class extends Controller<HTMLInputElement> {
  connect() {
    this.element.addEventListener("beforeinput", preventEmojiInput)
  }

  disconnect() {
    this.element.removeEventListener("beforeinput", preventEmojiInput)
  }
}
