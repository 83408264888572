import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLFormElement | HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement> {
  private form: HTMLFormElement

  initialize() {
    if (this.element.tagName === "FORM") {
      this.form = this.element as HTMLFormElement
    } else if (this.element.form) {
      this.form = this.element.form
    } else {
      throw new Error("form-validation controller can only be used with forms and form inputs")
    }

    this.form.addEventListener("turbo:submit-start", (event) => {
      const submitterController = this.application.getControllerForElementAndIdentifier(event.detail.formSubmission.submitter, "turbo-form-validation")
      const runThis = (!submitterController && (this.element === this.form)) || (submitterController && (this.element === event.detail.formSubmission.submitter))

      if (!runThis) {
        return
      }

      this.form.classList.add("was-validated")
      if (!this.form.checkValidity()) {
        event.detail.formSubmission.stop()
      }
    })
  }
}
