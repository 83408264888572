import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["countHolder", "input"]

  static values = {
    limitLength: Number,
    currentLength: Number,
  }

  declare readonly countHolderTarget: HTMLElement
  declare limitLengthValue: number
  declare currentLengthValue: number

  currentLengthValueChanged() {
    this.countHolderTarget.textContent = `${this.currentLengthValue}/${this.limitLengthValue}`
  }

  handleInput(event: InputEvent) {
    this.currentLengthValue = (event.currentTarget as HTMLInputElement).value.length
  }
}
