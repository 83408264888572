import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static values = {
    confirmUrl: String,
    confirmBtn: String,
    cancelBtn: String,
    message: String,
    header: String,
  }

  declare confirmUrlValue: string
  declare confirmBtnValue: string
  declare cancelBtnValue: string
  declare messageValue: string
  declare headerValue: string

  openModal(event) {
    event.preventDefault()
    const modalEl = document.querySelector("#confirmDeletionModal")
    const modal = Modal.getOrCreateInstance(modalEl)

    if (modal) {
      const confirmationMessage = modalEl.querySelector("#confirmation-message")
      const cancelButton = modalEl.querySelector("#cancel-button")
      const confirmButton = modalEl.querySelector("#confirm-button")
      const confirmheader = modalEl.querySelector("#confirm-header")
      const form = confirmButton?.closest("form")

      if (confirmationMessage) {
        confirmationMessage.textContent = this.messageValue
      }
      if (confirmheader) {
        confirmheader.textContent = this.headerValue
      }
      if (cancelButton) {
        cancelButton.textContent = this.cancelBtnValue
      }
      if (confirmButton && form) {
        const csrfToken = (document.getElementsByName("csrf-token")[0] as HTMLMetaElement).content
        const authenticityTokenField = form.querySelector(
          'input[name="authenticity_token"]',
        ) as HTMLInputElement
        authenticityTokenField.value = csrfToken
        form.setAttribute("action", this.confirmUrlValue)
        confirmButton.textContent = this.confirmBtnValue
      }

      modal.show()
    }
  }
}
