import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import { patch } from "@rails/request.js"

export default class extends Controller {
  [x: string]: any;
  static values = { bookUuid: String};

  openModal(event) {
    this._uploadModal()
  }

  _uploadModal() {
    const modalEl = document.getElementById("logo-upload-modal");
    const modal = Modal.getOrCreateInstance(modalEl);

    const onSave = async (event) => {
      const response = await fetch(event.detail.imageUrl);
      const imageBlob = await response.blob();
      await this.savePicture(this.bookUuidValue, imageBlob);

      modal.hide();
    }

    function onModalHidden() {
      modalEl.removeEventListener("hidden.bs.modal", onModalHidden);
      modalEl.removeEventListener("saveUpload", onSave);
    }

    modalEl.addEventListener("hidden.bs.modal", onModalHidden);
    modalEl.addEventListener("saveUpload", onSave);

    modal.show();
  }
  
  savePicture = async (bookId, image) => {
    const formData = new FormData();
    formData.append('book[logo]', image);
  
    const csrfTokenElement = document.getElementsByName("csrf-token")[0] as HTMLMetaElement | undefined;
    const csrfToken = csrfTokenElement?.content || '';

    const response = await patch(`/books/${bookId}`, {
      body: formData,
      headers: { "X-CSRF-Token": csrfToken },
    })

    if (!response.ok) {
      return alert('Error uploading file');
    }

    const updatedLogoUrl = URL.createObjectURL(image);
    if (updatedLogoUrl) {
      const logoImage: HTMLImageElement | null = document.querySelector('#book-logo img');
      if (logoImage) {
        logoImage.src = updatedLogoUrl; 
      }
      const bookLogos = document.querySelectorAll('.book-logo img');
      bookLogos.forEach((bookLogo: HTMLImageElement) => {
        bookLogo.src = updatedLogoUrl;
      });
    }
  };
  
}
