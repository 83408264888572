import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static values = {
    bookUuid: String
  }

  declare bookUuidValue: string

  async toggle(event) {
    const footer_hidden_val = event.currentTarget.checked

    const response = await patch(`/books/${this.bookUuidValue}`, {
      body: { book: { footer_hidden: footer_hidden_val } }
    })

    if (response.ok) {
      const columnElements = document.querySelectorAll(".book-page-cols .column");

      columnElements.forEach(column => {
        if (footer_hidden_val) {
          column.classList.add("hide_footer")
        } else {
          column.classList.remove("hide_footer")
        }
      })
    }
  }
}
