import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import { FetchResponse, get, post } from "@rails/request.js";

export default class extends Controller<HTMLFormElement | HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement> {
  static values = {
    appendFrom: String,
    modal: String,
    remote: Boolean,
    successEventName: String,
  }
  declare modalValue: string
  declare appendFromValue: string
  declare remoteValue: boolean
  declare successEventNameValue: string

  private form: HTMLFormElement

  initialize() {
    if (this.element.tagName === "FORM") {
      this.form = this.element as HTMLFormElement
    } else if (this.element.form) {
      this.form = this.element.form
    } else {
      throw new Error("form-validation controller can only be used with forms and form inputs")
    }

    this.form.addEventListener("submit", (event) => {
      const submitterController = this.application.getControllerForElementAndIdentifier(event.submitter, "form-validation")
      const runThis = (!submitterController && (this.element === this.form)) || (submitterController && (this.element === event.submitter))

      if (!runThis) {
        return
      }

      if (!this._checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
        return
      }

      this.validationSuccess(event)
    })

    if (this.appendFromValue) {
      this.form.addEventListener("formdata", (event) => {
        const source = new FormData(document.getElementById(this.appendFromValue) as HTMLFormElement)

        for (const pair of source) {
          event.formData.append(pair[0], pair[1])
        }
      })
    }
  }

  submit() {
    this.form.requestSubmit()
  }

  async validationSuccess(event: SubmitEvent) {
    if (this.modalValue) {
      event.preventDefault()
      this.openModal(this.modalValue)
    } else if (this.remoteValue) {
      event.preventDefault()
      const formData = new FormData(this.form)
      const submitter = event.submitter as HTMLButtonElement | HTMLInputElement
      if (submitter?.name) {
        formData.set(submitter.name, submitter.value)
      }
      const url = this.form.action
      const method = this.form.method

      let response: FetchResponse
      if (method === "GET") {
        response = await get(url, { query: formData })
      } else {
        response = await post(url, { body: formData })
      }

      if (!response.ok) {
        throw new Error(`Request to ${url} failed with status ${response.statusCode}`)
      }

      if (!this.successEventNameValue) {
        return
      }

      const detail = {
        ...Object.fromEntries(formData),
        response: response
      }

      this.element.dispatchEvent(
        new CustomEvent(this.successEventNameValue, {
          bubbles: true,
          detail
        }),
      )
    }
  }

  openModal(selector: string) {
    const modalEl = document.querySelector(selector)

    if (!modalEl) {
      throw new Error(`Unable to find modal element ${selector}`)
    }

    Modal.getOrCreateInstance(modalEl).show()
    return modalEl
  }

  _checkValidity() {
    this.form.classList.add("was-validated")
    return this.form.checkValidity()
  }
}
