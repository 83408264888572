import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  [x: string]: any;
  static targets = ["individualInput", "individualBtn"];

  static values = {
    hexcolor: String,
    uuid: String
  }

  declare hexcolorValue: string
  declare uuidValue: string

  connect() {
    (document.querySelector(`input[value="${this.hexcolorValue}"]`) as HTMLInputElement).checked = true
  }

  async updateColor(event) {
    const newColor = event.target.value

    await patch(`/books/${this.uuidValue}`, {
      body: { book: { hex_color: newColor } }
    })

    Array
      .from(document.getElementsByClassName("theme-color"))
      .forEach(e => (e as HTMLElement).style.setProperty("--accent-color", newColor))

    this.individualBtnTarget.style.setProperty("background", newColor)
    this.hexcolorValue = newColor
  }

  individual_color_trigger(){
    this.individualInputTarget.click();
  }
}
