import {Application, ControllerConstructor} from "@hotwired/stimulus"

declare global {
  interface Window {
    Stimulus: {
      storedControllers: Record<string, ControllerConstructor>
    }
  }
}

export default class ShadowPage extends HTMLElement {
  static formAssociated = true

  declare internals: ElementInternals

  constructor() {
    super()
    this.internals = this.attachInternals()

    if (!this.shadowRoot) {
      const template = this.querySelector("template[shadowrootmode]") as HTMLTemplateElement
      if (!template) {
        return
      }

      const mode = template.getAttribute("shadowrootmode") as ShadowRootMode
      const shadowRoot = this.attachShadow({ mode })
      shadowRoot.appendChild(template.content)
      template.remove()
    }

    Array.from(this.shadowRoot.children).forEach(child => {
      if (child instanceof HTMLStyleElement || child instanceof HTMLTemplateElement || child instanceof HTMLScriptElement || child instanceof HTMLLinkElement) {
        return
      }

      const app = Application.start(child)
      for (const storedControllersKey in window.Stimulus.storedControllers) {
        app.register(storedControllersKey, window.Stimulus.storedControllers[storedControllersKey])
      }
    })
  }
}